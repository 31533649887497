import html2canvas from "html2canvas";

export const exportTransparentPng=():Promise<string>=>{
    // Create a canvas to generate a transparent PNG
    return new Promise((resolve) => {
        // Create a new canvas element
        const canvas = document.createElement('canvas');

        // Define the canvas dimensions (you can adjust the width and height as needed)
        canvas.width = 20;  // Default width for transparent image
        canvas.height = 20; // Default height for transparent image

        // Get the 2D rendering context of the canvas
        const context = canvas.getContext('2d');

        if (context) {
            // Clear the canvas to ensure it's fully transparent
            context.clearRect(0, 0, canvas.width, canvas.height);
        }

        // Convert the canvas to a data URL in PNG format
        const transparentImageUrl = canvas.toDataURL('image/png');

        // Resolve the Promise with the generated transparent image URL
        resolve(transparentImageUrl);
    });
}
export const exportNode2PngDataURL = async (node: HTMLElement, quality: number = 0.8): Promise<string> => {
    // create a canvas
    const canvas = await html2canvas(node, {
        allowTaint: false,
        backgroundColor: null,
        useCORS: true,
        logging: false,
        ignoreElements:(element)=>{
            return !!element.getAttribute('ignoreImageExport');
        },
        width: node.offsetWidth,
        height: node.offsetHeight,
        scale: 2,
    })

    // uncomment to show the image export from canvas
    const imageUrl = canvas.toDataURL('image/png');

    return imageUrl;

}
